import { NavigationNode } from '../types/navigation'

export const navigationNodes: NavigationNode[] = [
    {
        label: 'Home',
        url: 'home',
        iconUrl: 'assets/icons/navigation/home.svg',
        requiredPermissions: [
            'get-resume-of-operation-by-partner-id',
            'get-resume-of-operation-by-sales-team-member-id',
            'get-resume-of-all-operation',
            'get-commercial-partners-badge',
        ],
        isRoot: true,
        nodes: [],
    },
    {
        label: 'Jornada',
        iconUrl: 'assets/icons/navigation/management.svg',
        requiredPermissions: [
            'list-partners-verifications-internal',
            'list-partners-verifications-external',
            'get-total-payment-volume',
            'get-credential-amount',
            'get-affiliates-automatic-anticipation',
            'get-active-base',
            'access-our-partnership-menu',
        ],
        isRoot: true,
        nodes: [
            {
                label: 'Minha Parceria',
                url: 'my-partnership',
                requiredPermissions: ['access-our-partnership-menu'],
                isRoot: false,
                parent: 'Jornada',
                nodes: [],
            },
            {
                label: 'Remuneração',
                url: 'remuneration',
                requiredPermissions: ['list-partners-verifications-internal'],
                isRoot: false,
                parent: 'Jornada',
                nodes: [],
            },
            {
                label: 'Remuneração',
                url: 'remuneration',
                requiredPermissions: ['list-partners-verifications-external'],
                requiredModule: 'remuneration',
                isRoot: false,
                parent: 'Jornada',
                nodes: [],
            },
            {
                label: 'Dashboard',
                url: 'dashboard',
                requiredPermissions: [
                    'get-total-payment-volume',
                    'get-credential-amount',
                    'get-affiliates-automatic-anticipation',
                    'get-active-base',
                ],
                isRoot: false,
                parent: 'Jornada',
                nodes: [],
            },
        ],
    },
    {
        label: 'Lojistas',
        iconUrl: 'assets/icons/navigation/shopkeepers.svg',
        requiredPermissions: [
            'create-affiliate-admin-app',
            'create-affiliate-admin-partner',
            'create-affiliate-biz-dev',
            'create-affiliate-sales-person',
            'create-new-account-pagar-me-psp-internal',
            'create-new-account-pagar-me-psp-admin-partner',
            'create-new-account-pagar-me-psp-sales-person',
            'get-all-draft-list',
            'get-draft-list-admin-partner',
            'get-draft-list-sales-person',
            'get-credentialed-affiliates',
            'get-credentialed-affiliates-partner-only',
            'get-credentialed-affiliates-salesman-only',
            'get-updates-of-credentialed-affiliate',
            'get-pagarme-psp-affiliations-report',
            'get-pagarme-psp-affiliate-detail',
            'allow-get-stone-accounts',
            'allow-create-stone-account',
            'allow-salesman-create-stone-account',
            'allow-owner-create-stone-account',
        ],
        isRoot: true,
        nodes: [
            {
                label: 'Pagamentos',
                isRoot: true,
                parent: 'lojistas',
                nodes: [
                    {
                        label: 'Criar Lojistas',
                        url: 'affiliate/new',
                        isRoot: false,
                        parent: 'Pagamentos',
                        requiredPermissions: [
                            'create-affiliate-admin-app',
                            'create-affiliate-admin-partner',
                            'create-affiliate-biz-dev',
                            'create-affiliate-sales-person',
                            'create-new-account-pagar-me-psp-internal',
                            'create-new-account-pagar-me-psp-admin-partner',
                            'create-new-account-pagar-me-psp-sales-person',
                        ],
                        nodes: [],
                    },
                    {
                        label: 'Listar Lojistas',
                        url: 'affiliate/report',
                        requiredPermissions: [
                            'get-credentialed-affiliates',
                            'get-credentialed-affiliates-partner-only',
                            'get-credentialed-affiliates-salesman-only',
                            'get-updates-of-credentialed-affiliate',
                            'get-pagarme-psp-affiliations-report',
                            'get-pagarme-psp-affiliate-detail',
                        ],
                        isRoot: false,
                        parent: 'Pagamentos',
                        nodes: [],
                    },
                    {
                        label: 'Rascunhos',
                        url: 'affiliate/draft',
                        requiredPermissions: ['get-all-draft-list', 'get-draft-list-admin-partner', 'get-draft-list-sales-person'],
                        isRoot: false,
                        parent: 'Pagamentos',
                        nodes: [],
                    },
                    {
                        label: 'Preço',
                        url: 'affiliate/pricing',
                        requiredPermissions: [
                            'approval-price-execute-get-init-admin-app',
                            'approval-price-execute-get-init-partner',
                            'approval-price-execute-get-init-bizdev',
                            'approval-price-execute-get-init-sales-person',
                        ],
                        isRoot: false,
                        parent: 'Pagamentos',
                        nodes: [],
                    },
                ],
            },
            {
                label: 'Banking',
                isRoot: true,
                parent: 'lojistas',
                nodes: [
                    {
                        label: 'Criar Conta',
                        url: 'stone-account/create',
                        requiredPermissions: [
                            'allow-create-stone-account',
                            'allow-salesman-create-stone-account',
                            'allow-owner-create-stone-account',
                        ],
                        isRoot: false,
                        parent: 'Banking',
                        nodes: [],
                    },
                    {
                        label: 'Listar Contas',
                        url: 'stone-account/list',
                        isRoot: false,
                        parent: 'Banking',
                        requiredPermissions: ['allow-get-stone-accounts'],
                        nodes: [],
                    },
                ],
            },
        ],
    },
    {
        label: 'Parceiros',
        iconUrl: 'assets/icons/navigation/partners.svg',
        url: 'partners/list-partners',
        requiredPermissions: ['get-commercial-partners-data'],
        isRoot: true,
        nodes: [],
    },
    {
        label: 'Integração',
        iconUrl: 'assets/icons/navigation/integrations.svg',
        isRoot: true,
        requiredPermissions: [
            'new_app_approval_flow',
            'approval-flow-execute-approve-qa',
            'get_approval_flow_report',
            'access-applications-menu',
        ],
        nodes: [
            {
                label: 'Setup',
                url: 'my-integration',
                isRoot: false,
                parent: 'Integração',
                requiredPermissions: ['allow-create-integration-external', 'allow-list-integration-internal'],
                requiredModule: 'partner_integration',
                nodes: [],
            },
            {
                label: 'Nova Aplicação',
                url: 'integration',
                requiredPermissions: ['new_app_approval_flow', 'approval-flow-execute-approve-qa', 'get_approval_flow_report'],
                isRoot: false,
                parent: 'Integração',
                nodes: [],
            },
            {
                label: 'Minha Integração',
                url: 'application',
                isRoot: false,
                parent: 'Integração',
                requiredPermissions: ['access-applications-menu'],
                nodes: [],
            },
            {
                label: 'Setup',
                url: 'my-integration',
                isRoot: false,
                parent: 'Integração',
                requiredPermissions: ['allow-create-integration-external'],
                requiredModule: 'partner_integration',
                nodes: [],
            },
        ],
    },
    {
        label: 'Configurações',
        iconUrl: 'assets/icons/navigation/settings.svg',
        isRoot: true,
        requiredPermissions: [],
        nodes: [
            {
                label: 'Dados Cadastrais',
                url: 'config/profile',
                isRoot: false,
                parent: 'Configurações',
                requiredPermissions: ['access-config-profile'],
                nodes: [],
            },
            {
                label: 'Gestão de Usuários',
                url: 'config/users',
                isRoot: false,
                parent: 'Configurações',
                requiredPermissions: ['access-config-users'],
                nodes: [],
            },
            {
                label: 'Cadastros',
                url: 'management-records',
                isRoot: false,
                parent: 'Configurações',
                requiredPermissions: ['access-management-records-menu'],
                nodes: [],
            },
            {
                label: 'Bloco de Notificações',
                url: 'notification-block/setup',
                isRoot: false,
                parent: 'Configurações',
                requiredPermissions: [
                    'get-all-notifications-block',
                    'get-all-notifications-block',
                    'get-notification-block',
                    'create-notification',
                ],
                nodes: [],
            },
            {
                label: 'Liberação de Módulos',
                url: 'modules-rollout',
                isRoot: false,
                parent: 'Configurações',
                requiredPermissions: ['get-pipelines', 'execute-pipelines'],
                nodes: [],
            },
            {
                label: 'Controle de Permissões',
                url: 'permission-control',
                isRoot: false,
                parent: 'Configurações',
                requiredPermissions: ['access-permissions-control-menu'],
                nodes: [],
            },
            {
                label: 'Documentos',
                url: 'documents',
                isRoot: false,
                parent: 'Configurações',
                requiredPermissions: ['create-new-document', 'list-terms-documents'],
                nodes: [],
            },
            {
                label: 'Gestão de Preço',
                url: 'price-management',
                isRoot: false,
                parent: 'Configurações',
                requiredPermissions: ['price-management-create-pricing-locale'],
                nodes: [],
            },
        ],
    },
]
